import React from 'react'
import styles from './Underconst.module.css'
import { ReactComponent as UnderconstSvg } from '../assets/underconstraction.svg'

interface UnderconstProps {
  moduleName?: string
  icon?: React.ReactElement
}

function Underconst({ moduleName, icon }: UnderconstProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        {icon ? (
          <div className={styles.icon}>{icon}</div>
        ) : (
          <UnderconstSvg className={styles.img} />
        )}
      </div>
      <div>
        <span className={styles.moduleName}>{moduleName}</span> Module <br />
        is Underconstraction!
      </div>
    </div>
  )
}

export default Underconst
