import React from 'react'
import styles from './Sidebar.module.css'
import * as Tooltip from '@radix-ui/react-tooltip'
import IconButton from '../ui-kit/IconButton'
import Divider from '../ui-kit/Divider'

import Teaser from './components/Teaser'
import Betatest from './components/Betatest'
import AppLogo from './components/AppLogo'

import { AnimatePresence } from 'framer-motion'
import {
  LeftDoubleArrowIcon,
  BarsIcon,
  AnalyticsIcon,
  UsersIcon,
  CardIcon,
  WorkersIcon,
  WorldIcon,
  PackageIcon,
  DashboardIcon,
  SubsIcon,
  SettingsIcon,
} from '../assets'

import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

const Sidebar: React.FC = () => {
  const [isSidebarVisible, setSidebarVisible] = useState(
    window.innerWidth >= 1104
  )

  useEffect(() => {
    if (window.innerWidth < 650) {
      setSidebarVisible(false)
    }
  }, [])

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible)
  }

  // Close sidebar on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1340 && isSidebarVisible) {
        setSidebarVisible(false)
      }
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isSidebarVisible])

  if (!isSidebarVisible) {
    return (
      <>
        <div className={styles.hideSidebar}>
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <span>
                  <IconButton onClick={() => setSidebarVisible(true)}>
                    <BarsIcon />
                  </IconButton>
                </span>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  className={styles.TooltipContent}
                  sideOffset={5}
                >
                  Show Sidebar
                  <Tooltip.Arrow className={styles.TooltipArrow} />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.sidebar}>
          <div className={styles.sidebarTop}>
            <div className={styles.sidebarHeader}>
              <NavLink to="/dashboard" className={styles.logoLink}>
                <AppLogo />
              </NavLink>
              <div className={styles.hideIcon}>
                <Tooltip.Provider>
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <span>
                        <IconButton onClick={toggleSidebar}>
                          <LeftDoubleArrowIcon />
                        </IconButton>
                      </span>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className={styles.TooltipContent}
                        sideOffset={5}
                      >
                        Hide Sidebar
                        <Tooltip.Arrow className={styles.TooltipArrow} />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
            </div>
          </div>
          <div className={styles.divider}>
            <Divider direction="H" />
          </div>
          <div className={styles.sideBarScroll}>
            {/* <div className={styles.topGrad}></div> */}
            <div className={styles.teaserPlaceholder}></div>
            <AnimatePresence>
              <Teaser
                to={'/dashboard'}
                title="Dashboard"
                icon={<DashboardIcon className={styles.icon} />}
              />
              <Teaser
                to={'/customers'}
                title="Customers"
                icon={<UsersIcon className={styles.icon} />}
              />
              <Teaser
                to={'/subscriptions'}
                title="Subscriptions"
                icon={<SubsIcon className={styles.icon} />}
              />
              <Teaser
                to={'/payments'}
                title="Payments"
                icon={<CardIcon className={styles.icon} />}
              />
              <Teaser
                to={'/plans'}
                title="Pricing Plans"
                icon={<PackageIcon className={styles.icon} />}
              />
              <Teaser
                to={'/analytics'}
                title="Analytics"
                icon={<AnalyticsIcon className={styles.icon} />}
              />
              <Teaser
                to={'/employees'}
                title="Employees"
                icon={<WorkersIcon className={styles.icon} />}
              />
              <Teaser
                to={'/localizations'}
                title="Localizations"
                icon={<WorldIcon className={styles.icon} />}
              />
              <Teaser
                to={'/settings'}
                title="Settings"
                icon={<SettingsIcon className={styles.icon} />}
              />
            </AnimatePresence>
            <div className={styles.teaserPlaceholder}></div>
            <div className={styles.bottomGrad}></div>
          </div>
          <div className={styles.sidebarBottom}>
            <Betatest />
          </div>
        </div>
        <div className={styles.backGround} onClick={toggleSidebar}></div>
      </div>
    </>
  )
}

Sidebar.defaultProps = { show: true }

export default Sidebar
