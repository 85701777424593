import React from 'react'
import styles from './Plans.module.css'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import IconButton from '../ui-kit/IconButton'
import PlanPreview from '../plans/components/PlanPreview'
import { MenuDotsIcon, PlusIcon } from '../assets'
import { Link } from 'react-router-dom'
import { Grid } from '@tremor/react'

function Plans() {
  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar />
        <section className={styles.section}>
          <Header pageTitle="Plans" />
          <div className={styles.dashboard}>
            <div className={styles.actionRow}>
              <Link to="/plans/new" className={styles.link}>
                <IconButton>
                  <PlusIcon />
                </IconButton>
              </Link>
              <div className={styles.pageTitle}>Pricing Plans</div>
              <div className={styles.buttonRow}>
                <IconButton>
                  <MenuDotsIcon />
                </IconButton>
              </div>
            </div>
            <div className={styles.planGrid}>
              <Grid numItemsMd={2} numItemsLg={3} className="mt-0 gap-6 w-full">
                <PlanPreview title="Free" link="/plans/free" />
                <PlanPreview title="Spark" link="/plans/spark" />
                <PlanPreview title="Blaze" link="/plans/blaze" />
                <PlanPreview title="Free" link="/plans/free" />
                <PlanPreview title="Spark" link="/plans/spark" />
                <PlanPreview title="Blaze" link="/plans/blaze" />
              </Grid>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Plans
