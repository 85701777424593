import styles from './SignIn.module.css'
import { Link } from 'react-router-dom'
// import SignInbyGoogle from '../auth/SignInbyGoogle'
import SignInbyEmail from '../auth/SignInbyMail'
import Copyright from '../ui-kit/Copyright'
// import LinkTo from '../ui-kit/LinkTo'
import Logo from '../ui-kit/DiglotLogo'
import Motion from '../ui-kit/Motion'

const SignIn = () => {
  return (
    <Motion kind="slideFromBottom">
      <div className={styles.column}>
        <div className={styles.formHeader}>
          <a href="https://diglot.app" className={styles.logoLink}>
            <Logo className={styles.appLogo} />
          </a>
          <h1>Admin Diglot</h1>
          <p className="text--center">
            Welcome back to Diglot.app. Enter your credentials to access your
            account.
          </p>
          <SignInbyEmail />
          <div className={styles.authLinks}>
            {/* <Link to="/" className="footer-link">
              Sign up
            </Link> */}
            <Link to="/forgot-password" className="footer-link">
              Forgot Password?
            </Link>
          </div>
          {/* <div className="text--center">
            <LinkTo />
          </div> */}
          <Copyright />
        </div>
      </div>
    </Motion>
  )
}

export default SignIn
