import React from 'react'
import styles from './Customers.module.css'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import Underconst from '../ui-kit/Underconst'
import { UsersIcon } from '../assets'

function Customers() {
  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar />
        <section className={styles.section}>
          <Header pageTitle="Customers" />
          <div className={styles.dashboard}>
            <Underconst
              moduleName="Customers"
              icon={<UsersIcon className={styles.hugeIcon} />}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default Customers
