import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { onAuthStateChanged } from 'firebase/auth'
import { auth } from './services/firebase'
import { useNavigate, Routes, Route } from 'react-router-dom'
import {
  appDispatch,
  destroyCurrentUser,
  fetchNotes,
  selectCurrentUser,
  setCurrentUser,
  getUserProfile,
} from './store'
import Loading from './ui-kit/Loading'
// import AppLayout from './views/AppLayout'
// import SignUp from './views/SignUp'
import SignIn from './views/SignIn'
import ForgotPassword from './views/ForgotPass'
import Dashboard from './views/Dashboard'
import Customers from './views/Customers'
import Subscriptions from './views/Subscriptions'
import Payments from './views/Payments'
import Plans from './views/Plans'
import Localizations from './views/Localizations'
import Employees from './views/Employees'
import Analytics from './views/Analytics'
import SettingsPage from './views/SettingsPage'
import PlanDetail from './plans/PlanDetail'

export const routes = {
  // signUp: '/sign-up',
  forgotPassword: '/forgot-password',
  signIn: '/sign-in',
  dashboard: '/dashboard',
  customers: '/customers',
  subscriptions: '/subscriptions',
  payments: '/payments',
  plans: '/plans',
  localizations: '/localizations',
  employees: '/employees',
  analytics: '/analytics',
  settings: '/settings',
}

function RedirectTo({ to }: { to: string }) {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [navigate, to])
  return null
}

function AppRoutes() {
  const [authLoading, setAuthLoading] = useState(true)
  const currentUser = useSelector(selectCurrentUser)
  const navigate = useNavigate()

  // console.log('currentUser from Redux:', currentUser)
  const dispatch = useDispatch()
  const [lastUpdated, setLastUpdated] = useState<number | null>(null)

  // Первый useEffect для инициализации lastUpdated
  useEffect(() => {
    if (currentUser?.lastUpdated !== null) {
      setLastUpdated(Number(currentUser?.lastUpdated ?? 0))
    }
  }, [currentUser?.id, currentUser?.lastUpdated])

  const [profileLoading, setProfileLoading] = useState(true)

  // Второй useEffect для вызова getUserProfile
  useEffect(() => {
    const fetchProfile = async () => {
      if (currentUser?.id && lastUpdated !== null) {
        const currentUpdated = Number(currentUser?.lastUpdated)
        if (currentUpdated !== lastUpdated) {
          // console.log('Dispatching getUserProfile')
          setProfileLoading(true) // Включаем индикатор загрузки
          await dispatch(getUserProfile(currentUser.id) as any)
          setLastUpdated(currentUpdated)
          setProfileLoading(false) // Выключаем индикатор загрузки
        }
      } else {
        setProfileLoading(false) // Если нет currentUser, также выключаем индикатор
      }
    }

    fetchProfile()
  }, [dispatch, currentUser?.id, lastUpdated, currentUser?.lastUpdated])

  useEffect(() => {
    return onAuthStateChanged(auth, (user) => {
      if (user) {
        appDispatch(
          setCurrentUser({
            name: user.displayName!,
            id: user.uid,
            email: user.email!,
            photoURL: user.photoURL!,
            createdDate: user.metadata.creationTime!,
            lastUpdated: user.metadata.lastSignInTime!,
          })
        )
        appDispatch(fetchNotes(user.uid))
        navigate(routes.dashboard)
      } else {
        appDispatch(destroyCurrentUser())
        navigate(routes.signIn)
      }
      setAuthLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (authLoading || profileLoading) {
    return <Loading />
  }

  return (
    <Routes>
      <Route
        path={routes.dashboard}
        element={
          currentUser ? <Dashboard /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path={routes.customers}
        element={
          currentUser ? <Customers /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path={routes.subscriptions}
        element={
          currentUser ? <Subscriptions /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path={routes.payments}
        element={currentUser ? <Payments /> : <RedirectTo to={routes.signIn} />}
      />
      <Route
        path={routes.plans}
        element={currentUser ? <Plans /> : <RedirectTo to={routes.signIn} />}
      />
      <Route
        path={routes.localizations}
        element={
          currentUser ? <Localizations /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path={routes.employees}
        element={
          currentUser ? <Employees /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path={routes.analytics}
        element={
          currentUser ? <Analytics /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path={routes.settings}
        element={
          currentUser ? <SettingsPage /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path="plans/:planId"
        element={
          currentUser ? <PlanDetail /> : <RedirectTo to={routes.signIn} />
        }
      />
      <Route
        path={routes.forgotPassword}
        element={
          currentUser ? (
            <RedirectTo to={routes.dashboard} />
          ) : (
            <ForgotPassword />
          )
        }
      />
      <Route
        path={routes.signIn}
        element={
          currentUser ? <RedirectTo to={routes.dashboard} /> : <SignIn />
        }
      />

      {/* <Route
        path={routes.signUp}
        element={currentUser ? <RedirectTo to={routes.notes} /> : <SignUp />}
      /> */}
      <Route path="*" element={<SignIn />} />
    </Routes>
  )
}

export default AppRoutes
