import React, { ReactNode } from 'react'
import styles from './Teaser.module.css'
import { NavLink } from 'react-router-dom'

interface TeaserProps {
  title?: string
  icon: ReactNode
  to?: string
}

const Teaser = (props: TeaserProps) => {
  const { title, icon, to = '' } = props

  return (
    <NavLink
      to={to}
      className={`${styles.wrapper} ${
        to === window.location.pathname ? styles.wrapperIsActive : ''
      }`}
    >
      <div className={styles.icon}>{icon}</div>
      <h4
        dangerouslySetInnerHTML={{ __html: title || 'Untitled Note' }}
        className={styles.title}
      />
    </NavLink>
  )
}

export default Teaser
