import React from 'react'
import styles from './Employees.module.css'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import Underconst from '../ui-kit/Underconst'
import { WorkersIcon } from '../assets'

function Employees() {
  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar />
        <section className={styles.section}>
          <Header pageTitle="Employees" />
          <div className={styles.dashboard}>
            <Underconst
              moduleName="Employees"
              icon={<WorkersIcon className={styles.hugeIcon} />}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default Employees
