import React from 'react'
import styles from './Header.module.css'
import UserMenu from './components/UserMenu'
import { BellIcon, RightArrowIcon } from '../assets'

interface HeaderProps {
  pageTitle: string
  subTitle?: string
}

function Header({ pageTitle, subTitle }: HeaderProps) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.pageName}>
        {pageTitle}

        {subTitle && (
          <span className={styles.subTitle}>
            <RightArrowIcon className={styles.arrowIcon} />
            {subTitle}
          </span>
        )}
      </div>
      <div className={styles.rightSlot}>
        <div className={styles.notifButton}>
          <BellIcon className={styles.bell} />
        </div>
        <UserMenu />
      </div>
    </div>
  )
}

export default Header
