import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

export interface DeepLState {
  sourceLanguage: string;
  targetLanguage: string;
  translatedText: string | null;
  singleWordTranslation: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const initialState: DeepLState = {
  sourceLanguage: 'UK',
  targetLanguage: 'EN',
  translatedText: null,
  singleWordTranslation: null,
  status: 'idle',
};

export const translateText = createAsyncThunk(
    'deepl/translateText',
    async (text: string, { getState }) => {
      const state = getState() as { deepl: DeepLState }; // Получаем полное состояние
      const { sourceLanguage, targetLanguage } = state.deepl; // Используем 'deepl' slice для доступа к свойствам
  
      const apiKey = 'YOUR_API_KEY_HERE';
      const url = 'https://api-free.deepl.com/v2/translate';
      const response = await axios.post(url, {
        auth_key: apiKey,
        text,
        source_lang: sourceLanguage,
        target_lang: targetLanguage,
      });
      return response.data.translations[0].text;
    }
  );
  

const deeplSlice = createSlice({
  name: 'deepl',
  initialState,
  reducers: {
    setSourceLanguage: (state, action: PayloadAction<string>) => {
      state.sourceLanguage = action.payload;
    },
    setTargetLanguage: (state, action: PayloadAction<string>) => {
      state.targetLanguage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(translateText.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(translateText.fulfilled, (state, action: PayloadAction<string>) => {
        state.status = 'succeeded';
        state.translatedText = action.payload;
      })
      .addCase(translateText.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setSourceLanguage, setTargetLanguage } = deeplSlice.actions;

export default deeplSlice.reducer;
