import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  collection,
  doc,
  setDoc,
  getDoc,
  Firestore,
  // getDocs,
  // query,
  // where,
} from 'firebase/firestore'

import {
  AuthError,
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'

import {
  AppDispatch,
  destroyError,
  isUserLike,
  RootState,
  resetNotes,
  resetUI,
  setError as setErrorAction,
  selectIsSaving,
  UserLike,
  UserFirestoreLike,
} from '.'

import { auth, firestore } from '../services/firebase'
import { getAuthErrorMessage, userConverter } from '../utilities/helpers'
// import defaultHotkeys from '../utilities/defaultHotkeys'

// const addUserToFirestore = async (
//   userId: string,
//   name: string,
//   email: string,
//   photoURL?: string
// ) => {
//   const userCollectionRef = collection(firestore, 'users').withConverter(
//     userConverter
//   )

//   // Проверяем, существует ли уже пользователь с данным email
//   const querySnapshot = await getDocs(
//     query(userCollectionRef, where('email', '==', email))
//   )
//   if (!querySnapshot.empty) {
//     console.error('User with this email already exists.')
//     return // Если существует, завершаем выполнение функции
//   }

//   // Если пользователь не существует, продолжаем добавление
//   const userRef = doc(userCollectionRef, userId)
//   const docSnapshot = await getDoc(userRef)

//   if (!docSnapshot.exists()) {
//     const newDate = new Date().toISOString()
//     const data: UserLike = {
//       id: userId,
//       name,
//       email,
//       accountType: 'customer',
//       currentPlan: 'trial',
//       availableWords: 5000,
//       usedWordsGrammar: 0,
//       usedWordsTranslate: 0,
//       createdDate: newDate,
//       lastUpdated: newDate,
//       hotkeys: defaultHotkeys,
//     }

//     if (photoURL) {
//       data.photoURL = photoURL
//     }

//     return setDoc(userRef, data)
//   }
// }

type UpdateUserProfilePayload = { userId: string } & UserLike

export const updateUserProfile = createAsyncThunk(
  'currentUser/updateInfo',
  async (payload: UpdateUserProfilePayload, thunkAPI) => {
    try {
      const { userId, ...userLikeFields } = payload

      // Передаем всю информацию в функцию для обновления в Firestore
      await updateUserInFirestore(firestore, userId, userLikeFields)

      return userLikeFields
    } catch (error) {
      thunkAPI.dispatch(setErrorAction('Failed to update user info'))
      console.error('Failed to update user info', error)
      throw error
    }
  }
)

const updateUserInFirestore = async (
  firestore: Firestore,
  userId: string,
  userData: UserLike
) => {
  const userRef = doc(collection(firestore, 'users'), userId)

  // Преобразуем UserLike в UserFirestoreLike
  let firestoreUser: UserFirestoreLike = userConverter.toFirestore(
    userData
  ) as UserFirestoreLike

  // Удаляем все поля со значением undefined
  Object.keys(firestoreUser).forEach((key) => {
    if ((firestoreUser as any)[key] === undefined) {
      delete (firestoreUser as any)[key]
    }
  })

  return setDoc(userRef, firestoreUser, { merge: true })
}

export const getUserProfile = createAsyncThunk(
  'currentUser/getUserProfile',
  async (userId: string) => {
    const userRef = doc(collection(firestore, 'users'), userId)
    const docSnapshot = await getDoc(userRef)
    if (docSnapshot.exists()) {
      return userConverter.fromFirestore(docSnapshot)
    } else {
      throw new Error('User does not exist')
    }
  }
)

export const createUserAndSignIn = createAsyncThunk(
  'currentUser/post',
  async (
    payload: { email: string; password: string; name: string },
    thunkAPI
  ) => {
    const trimmedName = payload.name.trim()
    thunkAPI.dispatch(destroyError())

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        payload.email,
        payload.password
      )
      await updateProfile(userCredential.user, { displayName: trimmedName })
      // await addUserToFirestore(
      //   userCredential.user.uid,
      //   trimmedName,
      //   userCredential.user.email!
      // )
      return {
        id: userCredential.user.uid,
        email: userCredential.user.email!,
        name: trimmedName,
      } as UserLike
    } catch (error) {
      console.error('Failed to add user to Firestore or update profile', error) // Для отладки
      thunkAPI.dispatch(setErrorAction(getAuthErrorMessage(error as AuthError)))
      throw error // Обязательно пробросить ошибку, чтобы она была обработана как rejected в вашем slice
    }
  }
)

export const selectCurrentUser = (state: RootState) => state.currentUser

export const signIn = createAsyncThunk(
  'currentUser/get',
  (payload: { email: string; password: string }, thunkAPI) => {
    thunkAPI.dispatch(destroyError())

    return new Promise<UserLike>((resolve, reject) => {
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then((userCredential) => {
          const user = userCredential.user
          if (user.email && user.displayName) {
            resolve({
              id: user.uid,
              email: user.email,
              name: user.displayName,
              createdDate: new Date().toISOString(),
              lastUpdated: new Date().toISOString(),
            })
          } else {
            reject(new Error('Missing email or displayName'))
          }
        })
        .catch((error: AuthError) => {
          thunkAPI.dispatch(setErrorAction(getAuthErrorMessage(error)))
          reject(error)
        })
    })
  }
)

export const signInWithGoogle = createAsyncThunk(
  'currentUser/googleSignIn',
  (_, thunkAPI) => {
    thunkAPI.dispatch(destroyError())

    return new Promise<UserLike>((resolve, reject) => {
      const provider = new GoogleAuthProvider()

      signInWithPopup(auth, provider)
        .then((result) => {
          console.log('Sign-in with Google successful, result:', result)

          if (
            result.user &&
            result.user.email &&
            result.user.displayName &&
            result.user.photoURL
          ) {
            resolve({
              id: result.user.uid,
              email: result.user.email,
              name: result.user.displayName,
              photoURL: result.user.photoURL,
              createdDate: new Date().toISOString(),
              lastUpdated: new Date().toISOString(),
              usedWordsTranslate: 0,
              usedWordsGrammar: 0,
              availableWords: 5000,
            })
          } else {
            console.log('Missing user information in the result:', result) // Добавленный лог
            reject(new Error('Missing user information'))
          }
        })
        .catch((error: AuthError) => {
          console.log('Sign-in with Google failed, error:', error) // Добавленный лог
          thunkAPI.dispatch(setErrorAction(getAuthErrorMessage(error)))
          reject(error)
        })
    })
  }
)

export const signOut = createAsyncThunk<
  void,
  void,
  { dispatch: AppDispatch; state: RootState; rejectValue: string }
>('currentUser/delete', async (payload, thunkAPI) => {
  const isSaving = selectIsSaving(thunkAPI.getState())

  if (isSaving) {
    console.log("Yep, it's saving")
    return thunkAPI.rejectWithValue(
      'We’re saving a note you edited. Please try again shortly.'
    )
  }

  try {
    await firebaseSignOut(auth)
    thunkAPI.dispatch(resetNotes())
    thunkAPI.dispatch(resetUI())
  } catch (error) {
    return thunkAPI.rejectWithValue(
      'Sorry, there was a server issue. Please try to sign out again.'
    )
  }
})

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: null as UserLike | null,
  reducers: {
    destroyCurrentUser: () => {
      return null
    },
    setCurrentUser: (state, action: PayloadAction<UserLike>) => {
      if (isUserLike(action.payload)) {
        return action.payload
      } else {
        return state
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createUserAndSignIn.fulfilled, (state, action) => {
      return { ...action.payload }
    })

    builder.addCase(createUserAndSignIn.rejected, () => {
      return null
    })

    builder.addCase(signIn.fulfilled, (state, action) => {
      return { ...action.payload }
    })

    builder.addCase(signIn.rejected, () => {
      return null
    })

    builder.addCase(signInWithGoogle.fulfilled, (state, action) => ({
      ...action.payload,
    }))

    builder.addCase(signInWithGoogle.rejected, () => null)

    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      if (state) {
        return { ...state, ...action.payload }
      }
      return state
    })

    builder.addCase(updateUserProfile.rejected, (state) => {
      // Обработка ошибки. Здесь вы можете сделать что-то ещё, если хотите
      return state
    })

    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      // console.log("До обновления стейта:", state);
      // console.log("Полученные данные:", action.payload);

      if (state) {
        const updatedState = { ...state, ...action.payload }
        // console.log("После обновления стейта:", updatedState);

        return updatedState
      }
      return state
    })

    builder.addCase(signOut.fulfilled, () => {
      return null
    })

    builder.addCase(signOut.rejected, (state) => {
      return state
    })
  },
})

export const { destroyCurrentUser, setCurrentUser } = currentUserSlice.actions
export default currentUserSlice.reducer
