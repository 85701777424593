import './App.css'
import AppRoutes from './AppRouts'
import { BrowserRouter } from 'react-router-dom'
import { useEffect } from 'react'

function App() {
  useEffect(() => {
    const theme = localStorage.getItem('color-scheme')
    const doc = document.firstElementChild

    if (theme === null) {
      localStorage.setItem('color-scheme', 'auto')
    }

    if (theme && doc) {
      switch (theme) {
        case 'auto':
          doc.setAttribute('color-scheme', 'auto')
          break
        case 'dark':
          doc.setAttribute('color-scheme', 'dark')
          break
        case 'light':
          doc.setAttribute('color-scheme', 'light')
          break
        default:
          break
      }
    }
  }, [])

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

export default App
