import React from 'react'
import styles from './Localizations.module.css'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import Underconst from '../ui-kit/Underconst'
import { WorldIcon } from '../assets'

function Localizations() {
  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar />
        <section className={styles.section}>
          <Header pageTitle="Localizations" />
          <div className={styles.dashboard}>
            <Underconst
              moduleName="Localizations"
              icon={<WorldIcon className={styles.hugeIcon} />}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default Localizations
