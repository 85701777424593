import React from 'react'
import styles from './Analytics.module.css'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import Underconst from '../ui-kit/Underconst'
import { AnalyticsIcon } from '../assets'

const Analytics = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar />
        <section className={styles.section}>
          <Header pageTitle="Analytics" />
          <div className={styles.dashboard}>
            <Underconst
              moduleName="Analytics"
              icon={<AnalyticsIcon className={styles.hugeIcon} />}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default Analytics
