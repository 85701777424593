import React from 'react'
import styles from './SettingsPage.module.css'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import Underconst from '../ui-kit/Underconst'
import { SettingsIcon } from '../assets'

function SettingsPage() {
  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar />
        <section className={styles.section}>
          <Header pageTitle="Settings" />
          <div className={styles.dashboard}>
            <Underconst
              moduleName="Settings"
              icon={<SettingsIcon className={styles.hugeIcon} />}
            />
          </div>
        </section>
      </div>
    </>
  )
}

export default SettingsPage
