import React, { ReactNode } from 'react'
import styles from './Card.module.css'

interface CardProps {
  children?: ReactNode
  padding?: '0' | '16px'
}

function Card({ children, padding }: CardProps) {
  // Определяем класс в зависимости от значения пропса padding
  const cardClassName = padding === '0' ? styles.cardNoPad : styles.card

  return <div className={cardClassName}>{children}</div>
}

// Устанавливаем значение по умолчанию для padding
Card.defaultProps = {
  padding: '16px',
}

export default Card
