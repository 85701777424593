import React, { createRef, useState, useRef, useEffect, useMemo } from 'react'

import styles from './Settings.module.css'
import * as Dialog from '@radix-ui/react-dialog'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import Divider from '../ui-kit/Divider'
import MenuItem from './components/MenuItem'
import UserAccount from './components/UserAccount'
import DangerZone from './components/DangerZone'
import Billing from './components/Billing'
import Interface from './components/Interface'
import Export from './components/Export'

import { Drawer } from 'vaul'

import {
  CloseIcon,
  SettingsIcon,
  CardIcon,
  UserIcon,
  DesktopIcon,
  ExportIcon,
  DangerIcon,
  LeftArrowIcon,
} from '../assets'

type ActiveMenuItem =
  | 'Account'
  | 'Billing'
  | 'Interface'
  | 'UserAccount'
  | 'DangerZone'
  | 'Export'

interface SettingsProps {
  onSelect: () => void
  onOpenChange: (open: boolean) => void
}

const Settings: React.FC<SettingsProps> = ({ onSelect, onOpenChange }) => {
  const [activeMenuItemIndex, setActiveMenuItemIndex] = useState<number>(0)
  const [activeMenuItem, setActiveMenuItem] =
    useState<ActiveMenuItem>('Account')

  const menuRef = useRef<HTMLDivElement>(null)
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const dangerZoneRef = useRef<HTMLButtonElement>(null)
  const billingRef = useRef<HTMLButtonElement>(null)
  const interfaceRef = useRef<HTMLButtonElement>(null)
  const userAccountRef = useRef<HTMLButtonElement>(null)
  const exportRef = useRef<HTMLDivElement>(null)
  const nextFocusableRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    switch (activeMenuItem) {
      case 'DangerZone':
        nextFocusableRef.current = dangerZoneRef.current
        break
      case 'Billing':
        nextFocusableRef.current = billingRef.current
        break
      case 'Interface':
        nextFocusableRef.current = interfaceRef.current
        break
      case 'Account':
        nextFocusableRef.current = userAccountRef.current
        break
      case 'Export':
        nextFocusableRef.current = exportRef.current
        break
      default:
        nextFocusableRef.current = null
        break
    }
  }, [activeMenuItem])

  const menuItems = useMemo(
    () => ({
      Account: 'Account',
      // Billing: 'Billing',
      Interface: 'Interface',
      // Export: 'Export',
      // DangerZone: 'DangerZone',
    }),
    []
  ) as Record<string, ActiveMenuItem>

  const menuItemsRefs = useRef(
    Array.from({ length: Object.keys(menuItems).length }, () =>
      createRef<HTMLButtonElement>()
    )
  )

  useEffect(() => {
    menuItemsRefs.current[activeMenuItemIndex]?.current?.focus()
  }, [activeMenuItemIndex])

  useEffect(() => {
    setActiveMenuItem(Object.values(menuItems)[activeMenuItemIndex])
    menuRef.current?.focus()
  }, [activeMenuItemIndex, menuItems])

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      closeButtonRef.current?.focus()
    }
  }, [open])

  const handleMenuItemClick = (menuItem: ActiveMenuItem) => {
    setActiveMenuItem(menuItem)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const menuItemsLength = Object.values(menuItems).length
    if (event.key === 'ArrowDown') {
      setActiveMenuItemIndex((prevIndex) => (prevIndex + 1) % menuItemsLength)
    } else if (event.key === 'ArrowUp') {
      setActiveMenuItemIndex(
        (prevIndex) => (prevIndex - 1 + menuItemsLength) % menuItemsLength
      )
    } else if (event.key === 'Tab') {
      event.preventDefault() // предотвратить стандартное поведение Tab
      menuItemsRefs.current[activeMenuItemIndex]?.current?.focus()

      // В зависимости от текущего активного пункта меню, устанавливаем фокус на соответствующий элемент
      switch (activeMenuItem) {
        case 'DangerZone':
          dangerZoneRef.current?.focus()
          break
        case 'Billing':
          billingRef.current?.focus()
          break
        case 'Interface':
          interfaceRef.current?.focus()
          break
        case 'Account':
          userAccountRef.current?.focus()
          break
        case 'Export':
          exportRef.current?.focus()
          break
        default:
          break
      }
    }
  }

  let windowTitle
  let content

  switch (activeMenuItem) {
    case 'DangerZone':
      windowTitle = 'Danger Zone'
      content = (
        <DangerZone
          ref={dangerZoneRef}
          onBackClick={() => setActiveMenuItem('Account')}
        />
      )
      break
    case 'Billing':
      windowTitle = 'Billing'
      content = <Billing show={true} ref={billingRef} />
      break
    case 'Interface':
      windowTitle = 'Interface'
      content = <Interface ref={interfaceRef} />
      break
    case 'Account':
      windowTitle = 'User Account'
      content = (
        <UserAccount
          onBackClick={() => setActiveMenuItem('Account')}
          ref={userAccountRef}
        />
      )
      break
    case 'Export':
      windowTitle = 'Export Notes'
      content = (
        <Export
          onBackClick={() => setActiveMenuItem('Account')}
          ref={exportRef}
        />
      )
      break
    default:
      windowTitle = 'Settings'
      content = null
      break
  }

  const getIcon = (menuItem: ActiveMenuItem) => {
    switch (menuItem) {
      case 'Billing':
        return <CardIcon className={styles.icon} />
      case 'Interface':
        return <DesktopIcon className={styles.icon} />
      case 'UserAccount':
        return <UserIcon className={styles.icon} />
      case 'DangerZone':
        return <DangerIcon className={styles.icon} />
      case 'Export':
        return <ExportIcon className={styles.icon} />
      default:
        return <UserIcon className={styles.icon} />
    }
  }

  const getMenuItemLabel = (menuItem: ActiveMenuItem) => {
    switch (menuItem) {
      case 'DangerZone':
        return 'Danger Zone'
      default:
        return menuItem
    }
  }

  // Mobile
  const [showMenu, setShowMenu] = useState(true)

  const handleMobileMenuItemClick = (menuItem: ActiveMenuItem) => {
    setActiveMenuItem(menuItem)
    setShowMenu(false)
  }

  const isMobile = useMediaQuery('(max-width: 644px)')

  function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(window.matchMedia(query).matches)

    useEffect(() => {
      const mediaQueryList = window.matchMedia(query)
      const handleMediaQueryChange = (event: MediaQueryListEvent) => {
        setMatches(event.matches)
      }

      mediaQueryList.addEventListener('change', handleMediaQueryChange)
      return () =>
        mediaQueryList.removeEventListener('change', handleMediaQueryChange)
    }, [query])

    return matches
  }

  return (
    <>
      {isMobile ? (
        <>
          <Drawer.Root
            onOpenChange={(open) => {
              if (onOpenChange) onOpenChange(open)
            }}
            closeThreshold={0.5}
            dismissible={false}
          >
            <Drawer.Trigger asChild>
              <DropdownMenu.Item
                className={styles.item}
                onSelect={(event) => {
                  event.preventDefault()
                  onSelect()
                }}
              >
                <SettingsIcon className={styles.icon} />
                Settings
              </DropdownMenu.Item>
            </Drawer.Trigger>
            <Drawer.Portal>
              <Drawer.Content
                className={styles.DrawerContent}
                style={{ top: showMenu ? '75%' : '60%' }}
              >
                {/* <div className={styles.drawerLineWrapper}>
                  <div className={styles.drawerLine}></div>
                </div> */}
                <div
                  className={styles.sidebar}
                  ref={menuRef}
                  onKeyDown={handleKeyDown}
                >
                  {showMenu ? (
                    <div className={styles.menu}>
                      <button
                        className={styles.closeButton}
                        onClick={() => {
                          // setShowMenu(false)
                          if (onOpenChange) {
                            onOpenChange(false)
                          }
                        }}
                      >
                        <CloseIcon className={styles.icon} />
                      </button>
                      <div className={styles.windowTitle}>Settings</div>
                      {Object.entries(menuItems).map(([key, value], index) => (
                        <MenuItem
                          key={index}
                          ref={menuItemsRefs.current[index]}
                          onClick={() => handleMobileMenuItemClick(value)}
                        >
                          {getIcon(value)}
                          {getMenuItemLabel(value)}
                        </MenuItem>
                      ))}
                    </div>
                  ) : (
                    <div className={styles.mobileContentWrapper}>
                      <div className={styles.titleWrapper}>
                        <div className={styles.mobileTitle}>{windowTitle}</div>
                        <button
                          className={styles.backButton}
                          onClick={() => setShowMenu(true)}
                        >
                          <LeftArrowIcon className={styles.icon} />
                          Back
                        </button>
                      </div>
                      {content}
                    </div>
                  )}
                </div>
              </Drawer.Content>
              <Drawer.Overlay className={styles.DrawerOverlay} />
            </Drawer.Portal>
          </Drawer.Root>
        </>
      ) : (
        <Dialog.Root
          onOpenChange={(isOpen) => {
            setOpen(isOpen)
            onOpenChange(isOpen)
          }}
        >
          <Dialog.Trigger asChild>
            <DropdownMenu.Item
              className={styles.item}
              onSelect={(event) => {
                event.preventDefault()
                onSelect()
              }}
            >
              <SettingsIcon className={styles.icon} />
              Settings
            </DropdownMenu.Item>
          </Dialog.Trigger>
          <Dialog.Portal>
            <Dialog.Overlay className={styles.DialogOverlay} />
            <Dialog.Content
              className={styles.DialogContent}
              onOpenAutoFocus={() => {
                closeButtonRef.current?.focus() // Устанавливаем фокус на кнопке закрытия при открытии
              }}
            >
              <div
                className={styles.sidebar}
                ref={menuRef}
                onKeyDown={handleKeyDown}
              >
                <div className={styles.windowTitle}>Settings</div>
                {Object.entries(menuItems).map(([key, value], index) => (
                  <MenuItem
                    key={index}
                    ref={menuItemsRefs.current[index]}
                    isActive={activeMenuItem === value}
                    onClick={() => handleMenuItemClick(value)}
                  >
                    {getIcon(value)}
                    {getMenuItemLabel(value)}
                  </MenuItem>
                ))}
              </div>
              <Divider direction="V" />
              <div className={styles.wrapper}>
                <Dialog.Title className={styles.DialogTitle}>
                  {windowTitle}
                </Dialog.Title>
                <Divider direction="H" />
                <div className={styles.content}>{content}</div>
              </div>
              <Dialog.Close asChild>
                <button
                  ref={closeButtonRef}
                  className={styles.IconButton}
                  aria-label="Close"
                  onKeyDown={(event) => {
                    if (event.key === 'Tab') {
                      event.preventDefault()
                      menuItemsRefs.current[
                        activeMenuItemIndex
                      ]?.current?.focus()
                    }
                  }}
                >
                  <CloseIcon className={styles.icon} />
                </button>
              </Dialog.Close>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      )}
    </>
  )
}

export default Settings
