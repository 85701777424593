import React from 'react'
import styles from './PlanDetail.module.css'
import Sidebar from '../sidebar/Sidebar'
import Header from '../header/Header'
import Button from '../ui-kit/Button'
import IconButton from '../ui-kit/IconButton'
import Card from '../ui-kit/Card'
import Input from '../ui-kit/Input'
import { LeftArrowIcon, MenuDotsIcon } from '../assets'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'

function PlanDetail() {
  const { planId } = useParams()

  return (
    <>
      <div className={styles.wrapper}>
        <Sidebar />
        <section className={styles.section}>
          <Header pageTitle="Plans" subTitle={planId} />
          <div className={styles.dashboard}>
            <div className={styles.content}>
              <div className={styles.actionRow}>
                <Link to="/plans" className={styles.link}>
                  <IconButton>
                    <LeftArrowIcon />
                  </IconButton>
                </Link>
                <div className={styles.planTitle}>{planId} Plan details</div>
                <IconButton>
                  <MenuDotsIcon />
                </IconButton>
              </div>
              <Card>
                <div className={styles.planForm}>
                  <div className={styles.inputRow}>
                    <Input
                      size="s"
                      autoComplete="given-name"
                      isRequired
                      label="Name"
                      // onChange={setName}
                      placeholder="Type your name"
                      // value={name}
                    />
                    <Input
                      size="s"
                      autoComplete="given-name"
                      isRequired
                      label="Name"
                      // onChange={setName}
                      placeholder="Type your name"
                      // value={name}
                    />{' '}
                    <Input
                      size="s"
                      autoComplete="given-name"
                      isRequired
                      label="Name"
                      // onChange={setName}
                      placeholder="Type your name"
                      // value={name}
                    />{' '}
                    <Input
                      size="s"
                      autoComplete="given-name"
                      isRequired
                      label="Name"
                      // onChange={setName}
                      placeholder="Type your name"
                      // value={name}
                    />{' '}
                    <Input
                      size="s"
                      autoComplete="given-name"
                      isRequired
                      label="Name"
                      // onChange={setName}
                      placeholder="Type your name"
                      // value={name}
                    />{' '}
                    <Input
                      size="s"
                      autoComplete="given-name"
                      isRequired
                      label="Name"
                      // onChange={setName}
                      placeholder="Type your name"
                      // value={name}
                    />
                    <Input
                      size="s"
                      autoComplete="given-name"
                      isRequired
                      label="Name"
                      // onChange={setName}
                      placeholder="Type your name"
                      // value={name}
                    />
                    <div className={styles.formButtons}>
                      <Button size="s">Save</Button>
                      <Button size="s" kind="secondary">
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default PlanDetail
