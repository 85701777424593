import React from 'react'
import styles from './UserMenu.module.css'
import Avatar from '../../ui-kit/Avatar'
import Divider from '../../ui-kit/Divider'
import Settings from '../../settings/Settings'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useSelector } from 'react-redux'
import { LogoutIcon } from '../../assets'
import {
  appDispatch,
  signOut,
  selectCurrentUser,
  // getUserProfile,
} from '../../store'

const User: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false)
  const [hasOpenDialog, setHasOpenDialog] = React.useState(false)
  const dropdownTriggerRef = React.useRef<HTMLButtonElement>(null)
  const focusRef = React.useRef<HTMLButtonElement | null>(null)

  const handleDialogSelect = (): void => {
    focusRef.current = dropdownTriggerRef.current
  }

  const handleDialogOpenChange = (open: boolean): void => {
    setHasOpenDialog(open)
    if (open === false) {
      setDropdownOpen(false)
    }
  }

  const handleSignOut = async () => {
    try {
      await appDispatch(signOut())
    } catch (error) {
      console.error('Error signing out:', error)
    }
  }

  const user = useSelector(selectCurrentUser)
  const userName = user?.name || ''
  const userMail = user?.email || ''

  return (
    <DropdownMenu.Root open={dropdownOpen} onOpenChange={setDropdownOpen}>
      <DropdownMenu.Trigger asChild>
        <button className={styles.wrapper} ref={dropdownTriggerRef}>
          <Avatar />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={styles.DropdownMenuContent}
          sideOffset={4}
          loop={true}
          side="bottom"
          align="end"
          alignOffset={-5}
          hideWhenDetached={true}
          hidden={hasOpenDialog}
          onCloseAutoFocus={(event) => {
            if (focusRef.current) {
              focusRef.current.focus()
              focusRef.current = null
              event.preventDefault()
            }
          }}
        >
          <div className={styles.userInfo}>
            <Avatar size="l" />
            <div className={styles.userText}>
              <div className={styles.userName}>{userName}</div>
              <div>{userMail}</div>
              <div>Administrator</div>
            </div>
          </div>
          <Divider direction="H" />
          <div className={styles.DropdownMenuContentWrapper}>
            <Settings
              onSelect={handleDialogSelect}
              onOpenChange={handleDialogOpenChange}
            />
          </div>

          <Divider direction="H" />
          <div className={styles.DropdownMenuContentWrapper}>
            <DropdownMenu.Item className={styles.DropdownMenuItem} asChild>
              <button onClick={handleSignOut} className={styles.signOutButton}>
                <LogoutIcon className={styles.iconLeft} />
                Log out <div className={styles.RightSlot}>⇧+⌘+Q</div>
              </button>
            </DropdownMenu.Item>
          </div>

          <DropdownMenu.Arrow className={styles.DropdownMenuArrow} />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default User
