import React from 'react'
import styles from './PlanPreview.module.css'
import Card from '../../ui-kit/Card'
import Divider from '../../ui-kit/Divider'
import IconButton from '../../ui-kit/IconButton'
import { EditIcon, MenuDotsIcon } from '../../assets'
import { Link } from 'react-router-dom'
import { Badge } from '@tremor/react'

interface PlanPreviewProps {
  title: string
  link: string
}

function PlanPreview({ title, link }: PlanPreviewProps) {
  return (
    <Link to={link} className={styles.link}>
      <Card padding="0">
        <div className={styles.planPreview}>
          <div className={styles.rows}>
            <div className={styles.row}>
              <h3 className={styles.planTitle}>{title}</h3>
              <Badge size="xs">live</Badge>
            </div>
            <div className={styles.row}>
              <p>Price</p>
              <p>$0.00</p>
            </div>
            <div className={styles.row}>
              <p>Frequrcy</p>
              <p>1 month</p>
            </div>
            <div className={styles.row}>
              <p>Words</p>
              <p>50k</p>
            </div>
            <div className={styles.row}>
              <p>Grammar</p>
              <p>True</p>
            </div>
            <div className={styles.row}>
              <p>ChatGPT</p>
              <p>False</p>
            </div>
          </div>
          <Divider direction="H" />
          <div className={styles.buttonsRow}>
            <Link to={link} className={styles.link}>
              <IconButton>
                <EditIcon />
              </IconButton>
            </Link>
            <IconButton>
              <MenuDotsIcon />
            </IconButton>
          </div>
        </div>
      </Card>
    </Link>
  )
}

export default PlanPreview
